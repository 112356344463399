import Vue from 'vue'
import Router from 'vue-router'
import Home from '../components/Home'
import About from '../components/About'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/home',
      meta: {
        title: '同乡出行'
      }
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        title: '同乡出行'
      }
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {
        title: '同乡出行'
      }
    }
  ]
})
