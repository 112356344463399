<template>
  <div class="main">
    <div class="header">
      <div class="title">
        <img src="../assets/img/logo.png" style="width: 70px; height: 70px;">
        <div style = "display: flex;">
          <label style="font-size: 22px; color: blueviolet">首页</label>
          <div style="font-size: 22px; margin-left: 100px; cursor:pointer;" @click="toAbout">关于我们</div>
        </div>
      </div>
      <div class="header-view">
        <img src="../assets/img/mobile.png" style="width: 250px"/>
        <div style="margin-left: 100px">
          <div style="display: flex; align-items: center">
            <img src="../assets/img/logo.png" style="width: 120px; height: 120px;">
            <div style="margin-left: 20px">
              <div style="font-size: 25px; font-weight: bold">同乡社区</div>
              <div style="font-size: 18px; margin-top: 20px; color: grey">老乡，上我车</div>
            </div>
          </div>
          <div style="display: flex; margin-top: 40px">
            <div>
              <img src="../assets/img/iphone-download.png" style="height: 60px">
            </div>
            <div style="margin-left: 30px">
              <img src="../assets/img/android-download.png" style="height: 60px">
            </div>
          </div>
        </div>
      </div>
      <div style="align-items: center; width: 100%; justify-content: center; display: flex">
        <div style="font-size: 12px">Copyright © 2021 广州一快科技有限公司版权所有 | </div>
        <a href="http://beian.miit.gov.cn/" style="font-size: 12px; margin-left: 5px">粤ICP备2021137428号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  methods:{
    toAbout(){
      return this.$router.replace({path:'/about'});
    }
  }
}
</script>

<style scoped>
  .main {
    width: 100%;
  }

  .header {
    width: 100%;
    height: 100%;
  }

  .title {
    width: 100% - 200px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 100px;
    padding-top: 20px;
    flex-wrap: wrap;
    align-items: center;
  }

  .header-view{
    width: 100%;
    height: 570px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
</style>
